.content-box-public {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-white);
    width: 100%;
    /* border-radius: 24px; */
    box-sizing: border-box;
    padding: 8px 0 8px 0;
    /* background-color: aquamarine; */
    /* gap: 24px */
}