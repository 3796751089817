/* .block__element--modifier */
/* TextareaWrite */

.textarea__container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    /* background-color: var(--color-white); */
    box-shadow: 0 0 0 2px var(--color-gray-200);
    border-radius: 8px;
    /* border: 2px solid var(--color-gray-200); */
    width: 100%;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.textarea__container.focused {
    box-shadow: 0 0 0 2px var(--color-black);
}

.textarea__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    color: var(--color-black);
}

.textarea-write-label p {
    padding: 8px 16px;
    background-color: var(--color-gray-100);
    border-radius: 80px;
}

.textarea__input {
    height: auto;
    min-height: 100px;
    border: none;
    font-size: 1em;
    color: var(--color-gray-900);
    resize: none;
    overflow: auto;
    background-color: transparent;
    line-height: 1.3;
}

.textarea__input:focus {
    outline: none;
}

/* TextareaWrite end */