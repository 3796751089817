/* .block__element--modifier */

.input-text__content-box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.input-text__label {
    top: 24%;
    margin: 0 0 0 10px;
    padding: 4px 8px;
    position: absolute;
    border-radius: 6px;
    font-size: 1em;
    color: var(--color-black);
    background-color: var(--color-white);
    box-sizing: content-box;
    transition: top ease-in-out .3s, font-size ease-in-out .3s;

}

.input-text__label--up {
    top: -18%;
    font-size: 0.8em;
}

.input-text--write {
    border-color: var(--color-black);
    border: 2px solid var(--color-gray-200);
    background-color: var(--color-white);
    font-size: 1em;
    padding: 16px;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    border-radius: 8px;
}

.input-text--write:focus {
    outline: none;
    border-color: var(--color-black);
}