.table__container {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    padding: 0;
    margin: 0;
}

.table__cell {
    position: relative;
    list-style-type: none;
    width: 100%;
}

.table__cell:last-child {
    border: none;
}

.cell__content {
    width: 100%;
    padding: 16px 0;
}

.cell__content--row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
}

.cell__content--column {
    gap: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cell-description {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: calc(100% - 16px - 16px - 48px);
    background-color: var(--color-white);
    padding: 24px 16px;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.table__cell:hover .cell-description {
    visibility: visible;
}

.progress-bar {
    height: 1px;
    width: 0;
    background-color: var(--color-primary);
    transition: width 1s linear;
}

.progress-bar.animate {
    width: 100%;
}