/* PublicMenu.css */
.public-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(50px);
    z-index: 3999;
    position: fixed;
    top: 0;
}

.public-menu__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: var(--widthContent);
    box-sizing: border-box;
}

.public-menu__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}

.public-menu__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    aspect-ratio: 1/1;
}

.public-menu__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.public-menu__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px 24px;
    font-size: 0.8rem;
    color: var(--color-white);
}

.public-menu__item--active {
    background-color: var(--color-gray-900);
    border-radius: 4px;
    padding: 4px 8px;
    color: var(--color-white);
}

.public-menu__preloader {
    width: 16px;
    height: 16px;
}

.public-menu__profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 32px;
    aspect-ratio: 1/1;
}

.public-menu__profile-icon {
    color: var(--color-white);
}

.public-menu__auth {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.public-menu__auth-link {
    text-decoration: none;
}

.public-menu__auth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 48px;
    border-radius: 80px;
    font-size: 0.8rem;
    color: var(--color-white);
}

.public-menu__auth-button--active {
    background-color: var(--color-gray-900);
    border-radius: 4px;
    padding: 4px 8px;
}

.public-menu__auth-button--signup {
    background-color: var(--color-primary);
}

/* Media queries for responsive design */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .public-menu__content {
        flex-direction: column;
        align-items: center;
    }

    .public-menu__left {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .public-menu__items {
        flex-direction: column;
        gap: 16px;
    }

    .public-menu__item {
        font-size: 1rem;
    }

    .public-menu__auth {
        flex-direction: column;
        gap: 16px;
    }

    .public-menu__auth-button {
        width: 100%;
        padding: 8px 16px;
    }
}

/* Small devices (phones, less than 768px) */
@media (max-width: 480px) {
    .public-menu {
        height: auto;
    }

    .public-menu__content {
        width: 100%;
        padding: 8px;
    }

    .public-menu__left {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .public-menu__items {
        flex-direction: column;
        gap: 8px;
    }

    .public-menu__item {
        font-size: 1.2rem;
    }

    .public-menu__auth {
        flex-direction: column;
        gap: 8px;
    }

    .public-menu__auth-button {
        width: 100%;
        padding: 8px;
    }
}