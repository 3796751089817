/* .block__element--modifier */

.addData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
}

.addData__content-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}

.addData_main-icon-box {
    background-color: var(--color-gray-100);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    aspect-ratio: 1/1;
    border-radius: 16px;
    box-sizing: border-box;
    cursor: pointer;
}