/* .block__element--modifier */

.popup__content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    background-color: var(--color-gray-100);
    backdrop-filter: blur(8px);
}

.popup__allert-content-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    box-sizing: border-box;
    /* background-color: rgba(0, 0, 0, 0.631); */
    background: var(--color-gray-100);
    /* backdrop-filter: blur(8px); */
}

.popup__content {
    border-radius: 16px 16px 0 0;
    /* background-color: var(--color-white); */
    max-width: var(--width-mobile-content);
    width: 100%;
    max-height: calc(100% - 48px - 32px);
    height: auto;
    overflow: hidden;
}

.popup__allert-content {
    border-radius: 16px;
    background-color: var(--color-white);
    max-width: var(--width-mobile-content);
    width: 100%;
    height: calc(100% - 48px - 32px - 72px);
    margin-bottom: 72px;
    overflow: hidden;
    /* height: auto; */
    /* min-height: 240px; */
}

.popup-dropdown-list__content {
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    /* background-color: var(--color-white); */
    max-width: var(--width-mobile-content);
    width: 100%;
    max-height: calc(100% - 48px - 32px);
    height: auto;

}

.popup__header {
    background-color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    height: 56px;
    padding: 0 8px 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-gray-200);
}

.popup__button-bar {
    /* max-width: 560px; */
    /* padding: 0 16px; */
    /* width: 100%; */
    /* height: 80px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

.popup__button--close {
    height: 32px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 80px; */
    /* background-color: var(--color-white); */
    cursor: pointer;
    /* background-color: var(--color-alarm); */
}

.popup__options {
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 56px);
    overflow-y: auto;
    background-color: var(--color-gray-100);
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripsterWhite.svg?alt=media&token=3409bcaf-ad17-47ff-b3c5-eb83971e650a'); */
    background-position: center 16px;
    background-repeat: no-repeat;
    background-size: auto 80px;
}

.popup__allert-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 56px);
    overflow-y: auto;
}

.popup__children-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 8px;
}

.popup__allert-button-bottom-bar {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
}

.popup__allert-button--cancel {
    height: 56px;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: var(--color-white);
    cursor: pointer;
    background-color: var(--color-black);
}

.popup__allert-button--confirm {
    height: 56px;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: var(--color-white);
    background-color: var(--color-primary);
    cursor: pointer;
}