/* .block__element--modifier */

/* AddPhoto.js */
.add-photo__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 40px;
  width: auto;
  border-radius: 80px;
  padding: 0 16px 0 8px;
  border: 0;
  cursor: pointer;
}

/* AddPhoto.js end */

.add-photo__icon-box {
  width: 32px;
  aspect-ratio: 1/1;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button.js */
.button__box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 56px;
  width: 100%;
  border-radius: 8px;
  padding: 0 24px 0 24px;
  border: 0;
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.button__icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 2px;
  border-radius: 8px;
  cursor: pointer;
  /* color: var(--color-white); */
}

/* Button.js end*/

/* SmallButton.js */

.button__box-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 34px;
  width: auto;
  border-radius: 80px;
  padding: 0 16px 0 16px;
  border: 0;
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-white);
}

/* SmallButton.js end*/