/* .block__element--modifier */

.bikes-rate {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex: 1;
    gap: 8px;
    width: 100%;
    background-color: var(--color-white);
}

.bikes__month-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    aspect-ratio: 2/1;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color-gray-100);
    cursor: pointer;
}

.bikes__month {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bikes__rate-in-day {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    /* align-items: flex-start; */
    width: 100%;
}

.bikes__sale {
    background-color: var(--color-warning);
    padding: 2px 6px;
    border-radius: 4px;
}