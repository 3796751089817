/* .block__element--modifier */
.swither__content-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 16px;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    position: relative;
}

.swither__control {
    width: 51px;
    height: 32px;
    border-radius: 80px;
    background-color: var(--color-gray-100);
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
}


.swither__control--off {
    background-color: var(--color-gray-300);
}

.swither__control--on {
    background-color: var(--color-success);
}

.swither__handle--off {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--color-white);
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease-in-out;
}

.swither__handle--on {
    transform: translateX(19px);
}


/* Checkbox */
.swither__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.swither__checkbox-priority {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.swither__checkbox-label {
    margin-left: 8px;
    font-size: 14px;
    color: var(--color-gray-900);
}

.swither__checkbox-priority-label {
    margin-left: 8px;
    font-size: 14px;
    color: var(--color-white);
}

.swither__checkbox input {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
    position: relative;
}

.swither__checkbox-priority input {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.54);
    border-radius: 2px;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
    position: relative;
}

.swither__checkbox input:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.swither__checkbox-priority input:checked {
    background-color: var(--color-white);
    border-color: var(--color-white);
}

.swither__checkbox input:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: url("https://res.cloudinary.com/dv7wszxg2/image/upload/v1688995738/check-solid_nv3rcf.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: brightness(0) invert(1);
}

.swither__checkbox-priority input:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: url("https://res.cloudinary.com/dv7wszxg2/image/upload/v1688995738/check-solid_nv3rcf.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: brightness(0) invert(0);
}

.swither__checkbox input:focus {
    border: 2px var(--color-primary) solid;
}

.swither__checkbox-priority input:focus {
    border: 2px var(--color-white) solid;
}

/* Checkbox end */