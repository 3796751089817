.user-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background-color: var(--color-white);
    width: 100%;
    border-radius: 24px;
    box-sizing: border-box;
}