/* .block__element--modifier */

.block-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-white);
    width: 100%;
    box-sizing: border-box;
    padding: 24px 0 24px 0;
}

.block-section__start-box {
    border-radius: 0 0 24px 24px;
}

.block-section__medium-box {
    border-radius: 24px;
}

.block-section__end-box {
    border-radius: 24px 24px 0 0;
}

.block-section__one-box {
    border-radius: 0;
}

.profile-block-section__content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 24px;
}

.profile-widget__content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}