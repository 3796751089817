.react-datepicker-wrapper {
    width: 100%;
}

.datepicker-content {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;

}

.datepicker-city-box {
    grid-column: span 8;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-auto-flow: dense; */
}

.datepicker-city {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 2px 8px;
    /* border-radius: 8px; */
    height: 32px;
    color: var(--color-gray-800);
    /* border-bottom: 1px var(--color-gray-300) solid; */
    cursor: pointer;
}

.datepicker-city.selected {
    /* background-color: var(--color-gray-100); */
    font-weight: bold;
    color: var(--color-black);
}

.datepicker-country-box {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: dense;
    width: 100%;
    gap: 16px;
    /* height: 40px; */
}

.datepicker-country {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* gap: 16px; */
    padding: 2px 8px;
    /* height: 32px; */
    /* border-radius: 8px; */
    /* font-size: 1.5rem; */
    /* font-weight: 500; */
    color: var(--color-gray-900);
    /* border-bottom: 1px var(--color-gray-200) solid; */
}

.datepicker-country.selected {
    /* background-color: var(--color-gray-100); */
    /* padding: 4px 8px; */
    /* height: 32px; */
    /* border-radius: 4px; */
    font-weight: bold;
    color: var(--color-black);
    /* border-bottom: 0px; */
}

.react-datepicker {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    border: 0;
    border-radius: 0;
    border-bottom: 0;
}

.react-datepicker__month-container {
    width: 100%;
    /* border-bottom: 1px var(--color-gray-100) solid; */
    /* padding-top: 24px; */
}

.react-datepicker__header {
    width: 100%;
    background-color: var(--color-white);
    border-color: var(--color-white);
    margin-bottom: 16px;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 8px;
    background-color: var(--color-white);
    color: var(--color-black);
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
}

.react-datepicker__day-name,
.react-datepicker__day {
    flex: 1;
    text-align: center;
}

.react-datepicker__day,
.react-datepicker__month-text {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
}

.react-datepicker__day:hover {
    background-color: #007bff20;
    color: var(--color-black);
}

/* Заголовки календаря */
.react-datepicker__current-month {
    font-size: 1rem;
    color: var(--color-gray-900);
    font-weight: 500;
    padding-bottom: 32px;
}

/* для дат в выбираемом диапазоне */
.react-datepicker__day--in-selecting-range {
    background-color: #007bff20 !important;
    color: var(--color-black) !important;
    /* transition: background-color 0.5s ease-in-out; */
}

/* для выбранных дат */
.react-datepicker__day--selected {
    background-color: var(--color-primary);
    color: var(--color-white);
    /* transition: background-color 0.5s ease-in-out; */
}

.react-datepicker__day--selected:hover {
    background-color: #007bff20 !important;
    /* transition: background-color 0.5s ease-in-out; */
}

/* для дат в выбранном диапазоне */
.react-datepicker__day--in-range {
    background-color: var(--color-gray-100);
    color: var(--color-gray-900);

}

/* для дат в выбранном диапазоне */
.react-datepicker__day--in-range:first-of-type,
.react-datepicker__day--in-range:last-of-type {
    border-radius: 8px;
}

.react-datepicker__month .react-datepicker__day--outside-month {
    background-color: transparent !important;
    color: transparent;
    border: none;
}

/* .datepicker-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 36px;
    box-sizing: border-box;
    height: 100%;
} */

/* .datepicker-box.open { */
/* display: grid; */
/* place-items: center; */
/* width: 100%; */
/* min-height: calc(100vh - 200px); */
/* } */


.datepicker-box.block {
    height: 0px;
    overflow: hidden;
}

.react-datepicker__navigation-icon::before {
    border-color: var(--color-gray-900);
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: block;
    height: 12px;
    width: 12px;
    position: absolute;
    top: 6px;
}

.react-datepicker__navigation {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: -32px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: none;
    text-indent: -999em;
    overflow: hidden;
    margin-top: 32px;
}

.react-datepicker__navigation:hover {
    background-color: var(--color-gray-100);
}