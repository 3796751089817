/* .block__element--modifier */

.rental-point-timetable {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
    width: 100%;
    cursor: pointer;
}

.rentalPointTimetable__week-day-content-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--color-gray-100);
}

.rentalPointTimetable__week-day {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
}

.rentalPointTimetable__icon {
    display: flex;
    flex-direction: row;
    aspect-ratio: 1/1;
}