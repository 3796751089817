/* .block__element--modifier */

.avatar_user {
    /* width: 120px; */
    aspect-ratio: 1/1;
    border-radius: 800px;
    background-color: var(--color-gray-100);
    background-image: url();
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}