/* .block__element--modifier */

.dropdown-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    background-color: var(--color-gray-100);
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
}

.dropdown-list__small {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    /* background-color: var(--color-gray-100); */
    background-color: var(--color-white);
    width: 100%;
    /* height: 56px; */
    box-sizing: border-box;
    border-radius: 80px;
    padding: 4px;
    cursor: pointer;
}

.dropdown-list__options li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56px;
    border-bottom: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    cursor: pointer;
    text-align: center;
    list-style-type: none;
}

.dropdown-list__options li:hover {
    background-color: var(--color-gray-100);
}

.dropdown-list__options li:last-child {
    border-bottom: none;
}