.container {
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-100);
    position: relative;
    text-align: center;
}

.hidden-element {
    visibility: hidden;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 4px;
    width: auto;
    background-color: var(--color-white);
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.container:hover .hidden-element {
    visibility: visible;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.widgetListFile__checkbox-box {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 2px;
    background-color: var(--color-white);
    border-radius: 4px;
    margin: 8px;
}

.widgetListFile__cleek-box {
    height: 100%;
    width: 100%;
}

.widgetListFile__cleek {
    display: none;
}