/* .block__element--modifier */
.segmented-controls__content-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    background-color: var(--color-gray-100);
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px;
}

.segmented-controls__marker--off {
    background-color: var(--color-gray-100);
    height: 48px;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
}

.segmented-controls__marker--on {
    background-color: var(--color-white);
    height: 48px;
    border-radius: 6px;
    border: 0;
}