/* .block__element--modifier */

.counter__content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    box-sizing: border-box;
}

.counter__controls-box {
    font-size: 1em;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.counter__controls-box.children {
    margin: 8px 0 8px 0;
}

.counter__quantity-button.active {
    background-color: var(--color-gray-100);
    transition: background-color 0.2s;
}

.counter__quantity-button.disable {
    background-color: var(--color-gray-100);
    color: var(--color-white);
    transition: background-color 0.2s;
}

.counter__quantity-button {
    aspect-ratio: 1/1;
    height: 56px;
    border-radius: 8px;
    color: var(--color-black);
    border: none;
    cursor: pointer;
}

.counter__input-text--write {
    border-color: var(--color-black);
    border: 2px solid var(--color-gray-200);
    background-color: var(--color-white);
    font-size: 1em;
    padding: 16px;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    border-radius: 8px;
}