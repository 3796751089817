/* .block__element--modifier */
.subtitle-block-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    width: 100%;
    padding: 0 0 0 0;
    box-sizing: border-box;
}